<template>
  <div class="gear-tab__wrapper">
    <div
      v-for="item in tabs"
      :key="item.id"
      v-tap="analysisData('1-7-8-2', item)"
      v-expose="analysisData('1-7-8-1', item)"
      :class="['gear-tab__list-item', {'tab-item__activated-item': item.id == modelValue, 'multi-tab__activated-item': tabs.length > 1 && item.id == modelValue }]"
      @click="selectTab(item.id)"
    >
      <div class="tab-item__title">
        {{ item.rangeTips }}
      </div>
      <div class="tab-item__status">
        <svg
          v-if="!item.isSatisfied"
          class="tab-item__status-icon"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 1C5.31836 1 3.9375 2.38086 3.9375 4.0625V5.375H2.625V12.375H11.375V5.375H10.0625V4.0625C10.0625 2.38086 8.68164 1 7 1ZM7 1.875C8.20483 1.875 9.1875 2.85767 9.1875 4.0625V5.375H4.8125V4.0625C4.8125 2.85767 5.79517 1.875 7 1.875ZM3.5 6.25H10.5V11.5H3.5V6.25Z"
            :fill="item.id == modelValue ? iconColor : '#999999'"
            :stroke="item.id == modelValue ? iconColor : '#999999'"
            stroke-width="0.2"
          />
        </svg>
        <svg
          v-else-if="[2, 4, 28].includes(+typeId)"
          class="tab-item__status-icon"
          width="12"
          height="11"
          viewBox="0 0 12 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.25 0.599976C3.53052 0.599976 2.9375 1.19299 2.9375 1.91248C2.9375 2.06628 2.96997 2.21155 3.01953 2.34998H0.75V4.97498H1.1875V10.6625H10.8125V4.97498H11.25V2.34998H8.98047C9.03003 2.21155 9.0625 2.06628 9.0625 1.91248C9.0625 1.19299 8.46948 0.599976 7.75 0.599976C6.98438 0.599976 6.46484 1.18103 6.12305 1.66638C6.0769 1.73132 6.04102 1.79456 6 1.85779C5.95898 1.79456 5.9231 1.73132 5.87695 1.66638C5.53516 1.18103 5.01562 0.599976 4.25 0.599976ZM4.25 1.47498C4.52344 1.47498 4.87891 1.76892 5.15234 2.15857C5.21899 2.25427 5.20874 2.2594 5.26172 2.34998H4.25C4.0022 2.34998 3.8125 2.16028 3.8125 1.91248C3.8125 1.66467 4.0022 1.47498 4.25 1.47498ZM7.75 1.47498C7.9978 1.47498 8.1875 1.66467 8.1875 1.91248C8.1875 2.16028 7.9978 2.34998 7.75 2.34998H6.73828C6.79126 2.2594 6.78101 2.25427 6.84766 2.15857C7.12109 1.76892 7.47656 1.47498 7.75 1.47498ZM1.625 3.22498H10.375V4.09998H6.4375V3.66248H5.5625V4.09998H1.625V3.22498ZM2.0625 4.97498H9.9375V9.78748H6.4375V5.41248H5.5625V9.78748H2.0625V4.97498Z"
            :fill="item.id == modelValue ? iconColor : '#999999'"
            :stroke="item.id == modelValue ? iconColor : '#999999'"
            stroke-width="0.2"
          />
        </svg>
        <svg
          v-else-if="[13, 22].includes(+typeId)"
          class="tab-item__status-icon"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 1.875L6.38281 1.98047L2.10547 6.30469L1.84766 6.5625L2.10547 6.83203L5.66797 10.3945L5.9375 10.6523L6.19531 10.3945L10.5195 6.11719L10.625 6V1.875H6.5ZM6.81641 2.625H9.875V5.68359L5.9375 9.59766L2.90234 6.5625L6.81641 2.625ZM8.75 3.375C8.54346 3.375 8.375 3.54346 8.375 3.75C8.375 3.95654 8.54346 4.125 8.75 4.125C8.95654 4.125 9.125 3.95654 9.125 3.75C9.125 3.54346 8.95654 3.375 8.75 3.375Z"
            :fill="item.id == modelValue ? iconColor : '#999999'"
            :stroke="item.id == modelValue ? iconColor : '#999999'"
            stroke-width="0.2"
          />
        </svg>

        <span class="tab-item__status-tip">{{ item.statusTips }}</span>
      </div>
    </div>
  </div>
</template>

<script>

const { IS_RW } = gbCommonInfo
export default {
  name: 'GearTab',
  // 组件传参
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    typeId: {
      type: [Number, String],
      default: 0
    },
    promotionId: {
      type: [Number, String],
      default: 0
    },
    fulfillStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      IS_RW
    }
  },
  computed: {
    iconColor(){
      return this.IS_RW ? '#F82854' : '#FA6338'
    }
  },
  // 方法实现
  methods: {
    selectTab(id) {
      if(this.modelValue != id) {
        this.$emit('change', id)
      }
    },
    analysisData(id, item){
      return {
        id: id,
        once: false,
        data: {
          promotion_typeid: this.typeId,
          promotion_code: this.promotionId,
          is_satisfied: item.satisfiedStatus ? 1 : 0,
          promotion_state: this.fulfillStatus,
          level: item.id + 1
        }
      }
    }
  },
  emits: ['change']
}
</script>

<style lang='less' scoped>
.gear-tab__wrapper{
  display: inline-flex;
  align-items: center;
  .gear-tab__list-item{
    flex: 1;
    text-align: center;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    &:only-child{
      cursor: default;
    }
  }
  .tab-item__title{
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: #666666;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .tab-item__status{
    font-size: 10px;
    font-weight: 400;
    color:#999;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .tab-item__status-tip{
    text-align: center;
    font-size: 10px;
    vertical-align: top;
    padding-left: 4px;
    text-transform: uppercase;
  }
  .tab-item__status-icon{
    position: relative;
    top: 2px;
  }
  .tab-item__activated-item {
    .tab-item__title{
      color: #222222;
    }
    .tab-item__status{
      color: #FA6338 /*rw: #F82854*/;
    }
  }
  .multi-tab__activated-item{
    &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #222222;
      bottom: -3px;
      left: 0;
    }
  }
}

</style>
